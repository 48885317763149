import { createStore } from 'vuex'

export default createStore({
  state: {
    Token: "none"
  },
  mutations: {
    SET_TOKEN: (state, value) => {
      state.Token = value
    },
    SET_Luckid: (state, value) => {
      state.Luckid = value
    }
  },
  actions: {
  },
  modules: {
  }
})
