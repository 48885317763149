import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: { name: 'blindBox' }
  },
  {
    path: '/blindBox',
    name: 'blindBox',
    meta: {
      title: '惊喜盲盒'
    },
    component: () => import('@/views/blindBox')
  },
  {
    path: '/blindBoxWinningList',
    name: 'blindBoxWinningList',
    meta: {
      title: '中奖记录'
    },
    component: () => import('@/views/blindBoxWinningList')
  },
  {
    path: '/blindBoxRule',
    name: 'blindBoxRule',
    meta: {
      title: '玩法规则'
    },
    component: () => import('@/views/blindBoxRule')
  },
  {
    path: '/allGoods',
    name: 'allGoods',
    meta: {
      title: '中奖记录'
    },
    component: () => import('@/views/allGoods')
  },
  {
    path: '/drawRecord',
    name: 'drawRecord',
    meta: {
      title: '抽奖记录'
    },
    component: () => import('@/views/drawRecord')
  },
  {
    path: '/luckDraw',
    name: 'luckDraw',
    meta: {
      title: '幸运大转盘'
    },
    component: () => import('@/views/luckDraw')
  },
  {
    path: '/luckDrawWinningList',
    name: 'luckDrawWinningList',
    meta: {
      title: '中奖记录'
    },
    component: () => import('@/views/luckDrawWinningList')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
